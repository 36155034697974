import { render, staticRenderFns } from "./redesign-step-2.vue?vue&type=template&id=81818c2e&scoped=true&"
import script from "./redesign-step-2.vue?vue&type=script&lang=ts&"
export * from "./redesign-step-2.vue?vue&type=script&lang=ts&"
import style0 from "./redesign-step-2.vue?vue&type=style&index=0&id=81818c2e&lang=scss&scoped=true&"
import style1 from "./redesign-step-2.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "81818c2e",
  null
  
)

export default component.exports