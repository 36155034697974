

































































































































































































































































































































import Vue from "vue";
import axios from "axios";
import router from "@/router";
import Layout from "@/router/layouts/register.vue";
import { required, decimal, maxLength } from "vuelidate/lib/validators";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { mapActions, mapGetters } from "vuex";
import { BusinessTypes, CountryCodes } from "@/types";
import Scriptjs from "scriptjs";
import RegistrationStepper from "@/components/registration-stepper.vue";
import ArrowForward from "@iconify-icons/ion/arrow-forward";
import ArrowBack from "@iconify-icons/ion/arrow-back";
//import _ from "lodash";
import debounce from "lodash/debounce";

declare const window: any;

export default Vue.extend({
  components: {
    Layout,
    RegistrationStepper,
    vSelect,
  },

  data() {
    return {
      apiKey: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
      autocomplete: null,
      currentStep: 1,
      map: null,
      emptyResults: false,
      // newLocation: null,
      // marker: null,
      name: "",
      address: "",
      postalCode: "",
      city: "",
      latitude: null,
      longitude: null,
      venueTypes: [] as Array<BusinessTypes>,
      countries: [] as Array<CountryCodes>,
      selectedVenueType: null,
      selectedCountry: null,
      isSubmitted: false,
      icon: {
        arrowNext: ArrowForward,
        arrowBack: ArrowBack,
      },
    };
  },

  //@ts-ignore
  validations: {
    name: { required },
    address: { required },
    postalCode: { required, maxLength: maxLength(10) },
    city: { required },
    latitude: {
      required,
      decimal,
      checkLatitude(latitude) {
        let latitudeRegex = new RegExp(
          "^(\\+|-)?(?:90(?:(?:\\.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\\.[0-9]{1,30})?))$"
        );
        return latitudeRegex.test(latitude);
      },
    },
    longitude: {
      required,
      decimal,
      checkLongitude(longitude) {
        let longitudeRegex = new RegExp(
          "^(\\+|-)?(?:180(?:(?:\\.0{1,6})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\\.[0-9]{1,30})?))$"
        );
        return longitudeRegex.test(longitude);
      },
    },
    selectedVenueType: { required },
    selectedCountry: { required },
  },

  async mounted(): Promise<void> {
    await this.fetchVenueData();
    await this.setCountries();
    await this.setBusinessTypes();
    await this.setTimezones();

    this.name = this.venueGoogleData.name
      ? this.venueGoogleData.name
      : localStorage.getItem("venue_name");
    this.address = this.venueGoogleData.address;
    this.postalCode = this.venueGoogleData.postal_code;
    this.city = this.venueGoogleData.city;
    this.latitude = this.venueGoogleData.latitude;
    this.longitude = this.venueGoogleData.longitude;

    this.countries = this.getCountries;
    this.venueTypes = this.businessTypes;
    this.setDefaultBusinessType();
    this.fetchSelectedCountry();
    this.track();

    this.createGoogleMapsScript();
  },

  computed: {
    ...mapGetters("venueModule", {
      getCountries: "GET_COUNTRY_CODES",
    }),
    ...mapGetters("registerModule", {
      venue: "GET_VENUE_DATA",
      venueCountryCode: "GET_VENUE_COUNTRY_CODE",
      venueGoogleData: "GET_VENUE_GOOGLE_DATA",
    }),
    ...mapGetters("globalModule", {
      businessTypes: "GET_BUSINESS_TYPES",
    }),
    ...mapGetters("globalModule", {
      timezones: "GET_TIMEZONES",
    }),
    timezoneId(): number {
      return this.timezones.find(
        t => t.country_code.toLowerCase() === this.selectedCountry.code
      ).id;
    },
    formatPostalCode: {
      get() {
        return this.postalCode;
      },
      set(value: any) {
        this.postalCode = value.toUpperCase();
      },
    },
    getFormattedAddress() {
      return this.address + "," + this.postalCode + " " + this.city;
    },
    showMap() {
      if (
        this.address &&
        this.postalCode &&
        this.city &&
        this.selectedCountry &&
        !this.emptyResults
      ) {
        return true;
      } else {
        return false;
      }
    },
  },

  methods: {
    ...mapActions("venueModule", {
      setCountries: "SET_COUNTRY_CODES",
    }),
    ...mapActions("registerModule", {
      setVenue: "SET_VENUE",
      createVenue: "CREATE_VENUE",
      fetchVenueData: "FETCH_VENUE_DATA",
      setCompanyBusinessType: "SET_COMPANY_BUSINESS_TYPE",
    }),
    ...mapActions("globalModule", {
      setBusinessTypes: "FETCH_BUSINESS_TYPES",
    }),
    ...mapActions("globalModule", {
      setTimezones: "FETCH_TIMEZONES",
    }),
    createGoogleMapsScript() {
      const apiKey = this.apiKey;
      const lang = this.$i18n.locale;
      Scriptjs(
        `https://maps.googleapis.com/maps/api/js?key=${apiKey}&language=${lang}`
      );
    },
    getLatLng() {
      return {
        lat: Number(this.latitude),
        lng: Number(this.longitude),
      };
    },
    googleMapsInit(zoom: number) {
      if (window.google) {
        const center = this.getLatLng();

        this.map = new window.google.maps.Map(document.getElementById("map"), {
          zoom: zoom || 18,
          center: center,
        });

        this.marker = new window.google.maps.Marker({
          position: center,
          map: this.map,
          draggable: true,
          title: this.address,
        });
        this.marker.addListener("dragend", () => {
          const position = this.marker.getPosition();
          var lat = position.lat(),
            lng = position.lng();

          if (String(lat).length > 12) {
            lat = lat.toFixed(9);
          }

          if (String(lng).length > 12) {
            lng = lng.toFixed(9);
          }
          this.latitude = lat;
          this.longitude = lng;
          this.centerMap();
        });
      }
    },
    searchAddress: debounce(async function () {
      const apiKey = this.apiKey;

      var address = this.getFormattedAddress;
      var components = "&components=";
      if (this.selectedCountry) {
        components += "country:" + this.selectedCountry.code;
      }
      var url = `https://maps.googleapis.com/maps/api/geocode/json?key=${apiKey}&address=${address}`;

      if (components) {
        url += components;
      }

      try {
        var response = await axios.get(url);
        var result = response.data.results[0];
        if (result) {
          if (result.hasOwnProperty("geometry")) {
            var location = result.geometry.location;
            this.latitude = location.lat;
            this.longitude = location.lng;
            this.emptyResults = false;
            if (!this.map) {
              this.googleMapsInit();
            } else {
              this.centerMap();
            }
          } else {
            this.emptyResults = true;
          }
        } else {
          this.emptyResults = true;
        }
      } catch (e) {
        console.error(e);
      }
    }, 500),
    centerMap() {
      let center = this.getLatLng();
      this.marker.setPosition(center);
      this.map.setCenter(center);
      this.map.setZoom(18);
    },
    fetchSelectedCountry() {
      if (this.venueCountryCode) {
        const filteredCountry = this.countries.filter(
          country => country.code === this.venueCountryCode.toLowerCase()
        );
        this.selectedCountry = !!filteredCountry[0] ? filteredCountry[0] : null;
      } else {
        this.selectedCountry = null;
      }
    },
    continueToStepOne() {
      router.push("/register/step-1?lang=" + this.$i18n.locale);
    },
    async continueToStepThree(): Promise<void> {
      this.isSubmitted = true;
      this.$v.$touch();

      if (this.$v.$invalid) {
        return this.$scrollTo(1);
      }

      const venuePayload = {
        name: this.name,
        address1: this.address,
        address2: this.postalCode,
        city: this.city,
        country_id: this.selectedCountry.id,
        latitude: Number(this.latitude),
        longitude: Number(this.longitude),
        venue_type_id: this.selectedVenueType.venue_type_id,
        state: 0,
        timezone_id: this.timezoneId,
      };

      this.setCompanyBusinessType(this.selectedVenueType.id);
      this.setVenue({
        payload: venuePayload,
        countryCode: this.selectedCountry.code,
      });
    },
    track() {
      this.$gtag.event("registration", {
        event_category: "registration",
        event_label: "step",
        value: "2",
      });
    },
    setDefaultBusinessType(): void {
      if (this.businessTypes === null || this.businessTypes.length === 0) {
        return;
      }

      const defaultBusinessType = this.businessTypes.find(businessType => businessType.default_business_type === true);

      if (defaultBusinessType) {
        this.selectedVenueType = {
          id: defaultBusinessType.id,
          label: defaultBusinessType.name_en,
          venue_type_id: defaultBusinessType.venue_type_id,
        };
      }
    },
  },
  watch: {
    showMap(val) {
      if (val && !this.map) {
        this.$nextTick(function () {
          this.searchAddress();
        });
      }
    },
    address() {
      if (this.map) {
        this.searchAddress();
      }
    },
    postalCode() {
      if (this.map) {
        this.searchAddress();
      }
    },
    city() {
      if (this.map) {
        this.searchAddress();
      }
    },
    selectedCountry() {
      if (this.map) {
        this.searchAddress();
      }
    },
  },
});
